import React, { useEffect, useState } from "react";

import {
  Button,
  FilledInput,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export default function EditTesis() {
  const { login, user } = useAuth();
  let { id } = useParams();
  const [tesisinTamAdi, setTesisinTamAdi] = useState("");
  const [themeID, setThemeID] = useState(0);
  const [tesisinYetkilisi, setTesisinYetkilisi] = useState("");
  const [yetkiliMail, setYetkiliMail] = useState("");
  const [yetkiliTelNo, setYetkiliTelNo] = useState("");
  const [password, setPassword] = useState("");
  const [tripadvisor, setTripadvisor] = useState("");
  const [holydaycheck, setHolydaycheck] = useState("");
  const [google, setGoogle] = useState("");
  const [booking, setBooking] = useState("");

  const handleSubmit = async (e) => {
    // bilgileri güncellemek için servis ucu bekleniyor
    console.log("Bilgiler Başarılı bir şekilde güncellendi");
  };

  const getData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.token}`);

    await fetch(
      `https://memorybook-backend.herokuapp.com/api/users/get-user-info/${id}`,
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    )
      .then((response) => response.text())
      .then((result) => {
        setTesisinTamAdi(JSON.parse(result).tesisName);
        setTesisinYetkilisi(JSON.parse(result).tesisYetkilisi);
        setYetkiliMail(JSON.parse(result).yetkiliMail);
        setYetkiliTelNo(JSON.parse(result).yetkiliTelNo);
        setTripadvisor(
          JSON.parse(result).QRLinks.length > 0
            ? JSON.parse(result).QRLinks[0]
            : ""
        );
        setHolydaycheck(
          JSON.parse(result).QRLinks.length > 0
            ? JSON.parse(result).QRLinks[1]
            : ""
        );
        setGoogle(
          JSON.parse(result).QRLinks.length > 0
            ? JSON.parse(result).QRLinks[2]
            : ""
        );
        setBooking(
          JSON.parse(result).QRLinks.length > 0
            ? JSON.parse(result).QRLinks[3]
            : ""
        );
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-100 d-flex align-items-center flex-column">
      <h1 className="text-center text-primary py-4">TESİS GÜNCELLE</h1>
      <div className="flex-colmn col-md-8">
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-basic"
            label="Tesisin Tam adı"
            variant="outlined"
            className="w-100"
            value={tesisinTamAdi}
            onChange={(text) => setTesisinTamAdi(text.target.value)}
            required
          />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="Modern Tasarım"
              control={
                <Radio
                  name="themeID"
                  value={4}
                  onChange={() => setThemeID(4)}
                />
              }
              label="Alaturka Tasarım"
            />
            <FormControlLabel
              value="Modern Tasarım"
              control={
                <Radio
                  name="themeID"
                  value={3}
                  onChange={() => setThemeID(3)}
                />
              }
              label="Modern Tasarım"
            />
            <FormControlLabel
              value="Defter Tasarım"
              control={
                <Radio
                  name="themeID"
                  value={2}
                  onChange={() => setThemeID(2)}
                />
              }
              label="Defter Tasarım"
            />
            <FormControlLabel
              value="Standart Tasarım"
              control={
                <Radio
                  name="themeID"
                  value={1}
                  onChange={() => setThemeID(1)}
                />
              }
              label="Standart Tasarım"
            />
          </RadioGroup>
          <TextField
            id="outlined-basic"
            label="Tesisin Yetkili Tam Adı"
            variant="outlined"
            className="w-100 my-3"
            value={tesisinYetkilisi}
            onChange={(text) => setTesisinYetkilisi(text.target.value)}
            required
          />
          <TextField
            type={"email"}
            id="outlined-basic"
            label="Yetkili Mail"
            variant="outlined"
            className="w-100 "
            value={yetkiliMail}
            onChange={(text) => setYetkiliMail(text.target.value)}
            required
          />
          <TextField
            id="outlined-basic"
            label="Yetkili Tel No"
            variant="outlined"
            className="w-100 my-3"
            value={yetkiliTelNo}
            onChange={(text) => setYetkiliTelNo(text.target.value)}
            required
          />
          {/* <TextField
            id="outlined-basic"
            label="Şifre"
            variant="outlined"
            className="w-100"
            type={"password"}
            value={password}
            onChange={(text) => setPassword(text.target.value)}
            required
          /> */}
          <TextField
            id="outlined-basic"
            label="TRIPADVISOR"
            variant="outlined"
            className="w-100 my-3"
            value={tripadvisor}
            onChange={(text) => setTripadvisor(text.target.value)}
            required
          />
          <TextField
            id="outlined-basic"
            label="HOLYDAYCHECK"
            variant="outlined"
            className="w-100"
            value={holydaycheck}
            onChange={(text) => setHolydaycheck(text.target.value)}
            required
          />
          <TextField
            id="outlined-basic"
            label="GOOGLE"
            variant="outlined"
            className="w-100 my-3"
            value={google}
            onChange={(text) => setGoogle(text.target.value)}
            required
          />
          <TextField
            id="outlined-basic"
            label="BOOKING"
            variant="outlined"
            className="w-100"
            value={booking}
            onChange={(text) => setBooking(text.target.value)}
            required
          />
          <div className="w-100 d-flex justify-content-center my-3">
            <Button
              type="submit"
              variant="contained"
              color="success"
              className="mt-3 w-25 py-2"
            >
              Güncelle
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
