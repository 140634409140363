import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import * as qs from "qs";
import Swal from "sweetalert2";
import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";

import Logo from "./icons/Logo.png";

const theme = createTheme();

export default function Login() {
  const { login, user } = useAuth();
  const [email, setMail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: "https://memorybook-backend.herokuapp.com/api/users/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        yetkiliMail: email,
        password: password,
      }),
    })
      .then(function (response) {
        console.log(response.data);
        login(response.data);
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        Swal.fire({
          icon: "error",
          title: "Giriş Hatası",
          text: error.response.data.message,
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} width={50} height={50} style={{ marginBottom: 5 }} />
          <Typography component="h1" variant="h5">
            GİRİŞ YAP
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Eposta Adresiniz"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(text) => setMail(text.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Şifre"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(text) => setPassword(text.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Giriş Yap
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
