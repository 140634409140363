import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, Doughnut } from "react-chartjs-2";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import ReactToPrint from "react-to-print";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Reports() {
  let { user } = useAuth();
  let componentRef = null;

  const [comments, setComments] = useState([]);
  const [trueComment, setTrueComment] = useState(0);
  const [falseComment, setFalseComment] = useState(0);
  const [trueGoogleComment, setGoogleTrueComment] = useState(0);
  const [falseGoogleComment, setGoogleFalseComment] = useState(0);
  const [tesisler, setTesisler] = useState([]);
  const [rating, setRating] = useState(0);
  const [age, setAge] = React.useState("");
  const [googlecomments, setGoogleComments] = React.useState([
    {
      id: 0,
      title: "Nilgün Özyurt",
      imgUrl:
        "https://lh3.googleusercontent.com/a-/ACB-R5SS_huOU9hL2E5oY5GmPL3oSgs4R_pND9CvLHx6BQ=s40-c-c0x00000000-cc-rp-mo-br100",
      comment:
        "Tek kelime ile kusursuzdu tatilimiz. Ben ki detaycı ve titiz biri olarak bunu söylüyorsam gerçekten öyledir. İlk defa bir işletmede kusur bulamadım. İster ailenizle ister arkadaşlarınızla ister tek başınıza gidin hepsine uygun. Gerek fiziki olarak otel şartlarının ve odaların modernliği gerekse yemekler ve hijyen olarak tazelik ve çeşitlilik çok şahaneydi. Kalabalık olmasına rağmen asla o kalabalığı hissetmiyorsunuz çünkü hiçbir hizmet aksamıyor. Vegan ve şekersiz yemek çeşitleri de mevcut benim gibi rahatsızlığı olanlar için. Bayat hiçbir şey görmedik bir gün bile. Deniz ve havuz koşulları ve akşam müzik etkinlikleri de çok keyifliydi. Ama enn önemlisi personel. Genç ve güler yüzlü bir ekip. Her an her sorunla ilgileniyorlar. Özellikle garsonlar İbrahim ve Umut arkadaşlara çok teşekkürler(yöneticiler tarafından takdiri hakediyor hepsi). Çok zariflerdi hepsi. Kesinlikle başka şehire gitsem yine tui blue otellerinde kalmak isteyeceğiz artık. Teşekkürler ve tebrikler tüm ekibe.",
      raiting: 5,
    },
    {
      id: 1,
      title: `Nazan Aktas`,
      imgUrl:
        "https://lh3.googleusercontent.com/a-/ACB-R5RbAiTLELHbZxVGz5STckYP4lmZ8PrPOBOH06aZGg=s40-c-c0x00000000-cc-rp-mo-br100",
      comment: `Otelde ikinci kez bulunuyoruz. Sunulan tüm hizmetler oldukça başarılı. Otel yönetiminin ilkeli, gayretli ve çalışanları ile birebir uygulamanın içinde olduğu açıkça kendini gösteriyor. Çalışan dostu, anlayışlı yönetim, çalışanların sorumluluklarını keyifle yerine getirmelerine,
      en iyi hizmeti vermeye gayret etmelerine yansımış. Otelde gün içerisinde yapacağınız çok çeşitli etkinlikler (okçuluk, bahçe gezisi, zumba, yoga vb.) profesyonelce planmış oldukça keyifli ve BLUE APP uygulaması ile kolayca organize edilebiliyor. Otelde kendimizi evimizde gibi hissettik. Taleplerimize en ideal çözümü hızlı bir şekilde samimiyetle sağladılar. Konaklamamızda bize yardımcı olan Ayla Hanımı samimi iletişimi ve hızlı organizasyon becerisi için tebrik ederiz. Otelin üst düzey yönetiminin her an sahada olması bizim için memnuniyet vericiydi.Otel müdürü Mehmet Bey, Ayla Hanım ve Zeynep Hanım başta olmak üzere tüm çalışanlara misafirperverlikleri ve sundukları kaliteli hizmet için çok teşekkür ederiz.
      NAZAN , AKIN , ECE AKTAŞ`,
      raiting: 5,
    },
    {
      id: 2,
      title: `Tolga CALISKAN`,
      imgUrl: `https://lh3.googleusercontent.com/a-/ACB-R5QtJpbydJaRy1zu8nc4wJNSQo4Ms3OfdPFuZb4M1A=s40-c-c0x00000000-cc-rp-mo-ba3-br100`,
      comment: `Tesisten genel olarak mutlu ayrıldık.
      Pozitif tarafları: Personelin güleryüzü ve yardımseverliliği, yemek çeşitliliği, temizliği, odaların bakımlı olması ve peyzaj güzelliği.
      Negatif tarafları: çok sivrisinek vardı çok. Alkol çeşitliliğinin daha fazla olmasını tercih ederdim bazı kokteyller ücretli idi. Zaten yerli içki ve meşrubat ile yapıyorsunuz neden ücret? Çok saçma...  ithal içkilerden ücret talep etmenizi fiyat performans olarak eleştirmiyorum. Çocuk dostu bir otel olduğundan çok fazla çocuk bebek vardı. Bazen kendinizi kreşte gibi hissediyorsunuz. Çocuğu olmayan çiftler bu nedenle sezonda bir kez daha düşünmeli.
      Genel olarak tesis temiz, bakımlı ve kaliteliydi.`,
      raiting: 5,
    },
    {
      id: 3,
      title: `BeYaZ GLÜmm'42`,
      imgUrl: `https://lh3.googleusercontent.com/a-/ACB-R5RJUyc1zUi5vo6J5CE8LJFVhOJJvgFQAY10MWV8=s40-c-c0x00000000-cc-rp-mo-br100`,
      comment: `Otelenizde geldim memnun kaldım odalar temiz personel güler yüzlü ilgileniliyor restorant da Ekrem bey e teşekür ediyorum bizimle çok ilgilendi otele giderseniz restorana Ekrem i mutlaka bulun otel müdürü Mehmet bey e de teşekür ediyoruz`,
      raiting: 5,
    },
    {
      id: 4,
      title: `Fatma Gülen`,
      imgUrl:
        "https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/eb/e3/default-avatar-2020-59.jpg",
      comment: `Merhabalar, az önce check out yaptığımız 5 gecelik tatilimizde gönlümüz sizlerle kaldı, uzun zamandır yapamadığımız o huzurlu tatili bizlere sunduğunuz için çok teşekkür ederiz.
    Otelin tüm çalışanlarının güler yüzü ve sizlere verdikleri hizmetle mutlulukları hissediliyor. Tüm emekleriniz için Tui Blue Palm Garden takımına teşekkürlerimizi sunarız.
    6 yıldız verebilsek verirdik tüm aile olarak hiçbir konudan memnuniyetsiz olmadık. Yemeklerin lezzeti ve çeşitliliği, etkinliklerin kalitesi, çocuk kulübünün çoğu otelden (daha pahalı oteller de var bu bahsettiğim çoğu otel kısmında) çok çok daha iyi olması, çocuğunuzu gönül rahatlığıyla emanet edebileceğiniz sevecen ve güvenilir abiler ve ablalar bizim için çok memnuniyet verici oldu.
    Temizlik çok güzel her gün tertemiz odamıza geldik, ortamın nezih olması nedeniyle huzurlu ve sakin bir tatildi. Gün içindeki etkinlikler çok keyifli tek sorun çok sıcak ama bu da Side'nin özelliği zaten sıcak olmayınca da tatil yaptığını hissedemiyor insan.
    Oturup yorum yazacak kadar etkilendik ve çok beğendik herşeyi.
    Tesis yüz üzerinden zaten 99 tek olarak bir tavsiye vermek istiyorum o da (biz suite odada kalıyorduk) arka odadaki klimaların direkt olarak yatağa üflemesi. Biz bu konudan dolayı zorlanmadık çünkü odaların ısı yalıtımı çok iyi olduğundan zaten soğuk kalıyor sabaha kadar odalar (alt katta kaldık bu nedenle üst katları bilemiyorum) ama otel tekrar yenilenir veya odaların dekoru değişir ise bu konuyu da dikkate alabilirsiniz bence çok çok iyi olacaktır çünkü yatağa klima üflediğinde çok üşünebilir biz açamadık şahsen. Sadece bunu belirtebiliriz sizlere faydalı olması adına. Bunun dışında harika bir 5gece geçirdik. Muhakkak tekrar görüşeceğiz, herşey için tekrar çok teşekkür eder, iyi çalışmalar dileriz.
    `,
      raiting: 5,
    },
    {
      id: 5,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      title: `ERAY YIGIT`,
      comment: `Bu otelde ilk kez tatil yaptık. Çok fazla iyi yorum okumuştum gitmeden. O yorumların hepsi haklı ve doğruymuş.Otele girişte gösterilen ilgi alaka ve güleryüz sonrası doğru bir seçim yaptığımı anladım. Tam bir aile oteli. Odalar, restoranlar, ortak alanlar, sahil tertemiz pırıl pırıl (havuzu kullanmadığımız için yorum yapamıyorum). Çalışanların ilgisi güler yüzü koşuşturmaları üst seviyede. Yemekleri lezzetleri kullanılan malzemeleri çok iyi. Şefler ustalar işinin yemeklerinin başında. Yerken aman dokunmasın diye terreddüt etmiyorsunuz (Alkol kullanmadığım için o konuda yorum yapamıyorum). Tatil köyü konseptinde.Koca koca binalar asansörde sıra beklemeler uzun koridorlarda yürümeler yok. Gece eğlenceleri organizasyonları nedir bilmiyorum ses geliyordu ama katılmadık:) birazda kafa dinlemeye gittik çünkü. Denizi biraz erken derinleşiyor ve çakıllı. Ama çakıl demek temizlik demek bence. Dalgayla birlikte kumlu sahiller çekilmez oluyor ama yinede tercih meselesi. Dalga derinlik çakıl bir buçuk yaşında oğlumuzla bizi mağdur etmedi öyle diyeyim.Çokta keyif aldık. Bir çok otele gittim bırakın Müdürü çalışanlarını bile göremiyorsunuz. Otelin genel müdürü sürekli işinin başında ve sahada. Neredeyse her misafirle tek tek ilgileniyor. İnanın ben olsam o kadar yapamazdım. Buradan tüm Palm garden çalışanlarına teşekkür ediyorum. önümüzdeki yıl nasip olursa tatilimizin adresi yine aynı olacak.
      `,
      raiting: 5,
    },
    {
      id: 6,
      title: `Eray`,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      comment: `Tek kelime ile kusursuzdu tatilimiz. Ben ki detaycı ve titiz biri olarak bunu söylüyorsam gerçekten öyledir. İlk defa bir işletmede kusur bulamadım. İster ailenizle ister arkadaşlarınızla ister tek başınıza gidin hepsine uygun. Gerek fiziki olarak otel şartlarının ve odaların modernliği gerekse yemekler ve hijyen olarak tazelik ve çeşitlilik çok şahaneydi. Kalabalık olmasına rağmen asla o kalabalığı hissetmiyorsunuz çünkü hiçbir hizmet aksamıyor. Vegan ve şekersiz yemek çeşitleri de mevcut benim gibi rahatsızlığı olanlar için. Bayat hiçbir şey görmedik bir gün bile. Deniz ve havuz koşulları ve akşam müzik etkinlikleri de çok keyifliydi. Ama enn önemlisi personel. Genç ve güler yüzlü bir ekip. Her an her sorunla ilgileniyorlar. Özellikle garsonlar İbrahim ve Umut arkadaşlara çok teşekkürler(yöneticiler tarafından takdiri hakediyor hepsi). Çok zariflerdi hepsi. Kesinlikle başka şehire gitsem yine tui blue otellerinde kalmak isteyeceğiz artık. Teşekkürler ve tebrikler tüm ekibe.`,
      raiting: 5,
    },
    {
      id: 7,
      title: `SAMING`,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      comment: `Lafı çok uzatmadan titiz biri olarak anlatayım; hemen hemen her yönüyle mükemmel bir tesis. Başta, Genel Müdür Mehmet Çubukçuoğlu, Halkla İlişkiler Müdürü Cansu Hanım ve tüm yönetimi tebrik ediyor ayrı ayrı teşekkürlerimi iletiyorum. Buradan ve tatil sitelerinden yapılan yorumlara bakarak ve merâk ederek gitmeye karar verdik ve iyi ki gitmişiz, abartı gibi düşünülen her şey doğru çıktı. Fiziksel imkanlar yeterli, yemekler ve tatlılar çok iyi, yemek salonunda karmaşa yok, sakin ve her zaman rahatça yer oluyor, hemen ne istediğiniz soruluyor, personel özenle seçilmiş ve eğitim görmüş, her yer temiz, bir çok yerde garson sizden kaçar, göz teması kurmaz, burada sizi takip ediyorlar, tüm çalışanlar çok candan ve kibarlar. Doğa ve peyzaj çok iyi, havuz biraz sıcak ama sorun olmadı, hem dinlendik hem eğlendik. Daha çok şey yazabilirim ama yazdıklarım her şeyi özetlemiştir diye düşünüyorum. Herkese tavsiye ederim.`,
      raiting: 5,
    },
    {
      id: 8,
      title: `antikgezgin`,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      comment: `Otelde ikinci kez bulunuyoruz. Sunulan tüm hizmetler oldukça başarılı. Otel yönetiminin ilkeli, gayretli ve çalışanları ile birebir uygulamanın içinde olduğu açıkça kendini gösteriyor. Çalışan dostu, anlayışlı yönetim, çalışanların sorumluluklarını keyifle yerine getirmelerine,
      en iyi hizmeti vermeye gayret etmelerine yansımış. Otelde gün içerisinde yapacağınız çok çeşitli etkinlikler (okçuluk, bahçe gezisi, zumba, yoga vb.) profesyonelce planmış oldukça keyifli ve BLUE APP uygulaması ile kolayca organize edilebiliyor. Otelde kendimizi evimizde gibi hissettik. Taleplerimize en ideal çözümü hızlı bir şekilde samimiyetle sağladılar. Konaklamamızda bize yardımcı olan Ayla Hanımı samimi iletişimi ve hızlı organizasyon becerisi için tebrik ederiz. Otelin üst düzey yönetiminin her an sahada olması bizim için memnuniyet vericiydi.Otel müdürü Mehmet Bey, Ayla Hanım ve Zeynep Hanım başta olmak üzere tüm çalışanlara misafirperverlikleri ve sundukları kaliteli hizmet için çok teşekkür ederiz.
      NAZAN , AKIN , ECE AKTAŞ`,
      raiting: 5,
    },
    {
      id: 9,
      title: `seraptapan`,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      comment: `Muhteşem, tek kelimeyle kusursuz bir tatil geçirdik, personelin ilgi alakası, hotelin temizliği, ev tadında, huzurunda bir tatildi, tüm ekibe tapan ailesi olarak teşekkür ederiz.`,
      raiting: 5,
    },
  ]);

  const percentageTripadCalculation = (value) => {
    return Number(((value / (trueComment + falseComment)) * 100).toFixed(2));
  };

  const percentageGoogleCalculation = (value) => {
    return Number(((value / (trueComment + falseComment)) * 100).toFixed(2));
  };

  const getData = async () => {
    let raitingTotal = 0;
    let ratingLength = 0;
    let result = 0;

    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: "https://memorybook-backend.herokuapp.com/api/users/",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(function (response) {
        setTesisler(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    if (comments.length != 0) {
      comments.forEach((element) => {
        ratingLength++;
        raitingTotal += element.rating;
      });
    }

    googlecomments.forEach((element) => {
      ratingLength++;
      raitingTotal += element.raiting;
    });

    result = raitingTotal / ratingLength;

    setRating(result);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="d-flex justify-content-center flex-column py-4 px-4">
      <h1 className="text-primary text-center my-3">Raporlar</h1>
      <FormControl fullWidth className="w-100 mb-4">
        <InputLabel id="demo-simple-select-label">Tesisler</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={async (event) => {
            setAge(event.target.value);

            let trueCommentCount = 0,
              falseCommentCount = 0,
              trueGoogleCount = 0,
              falseGoogleCount = 0;
            await axios({
              method: "get",
              maxBodyLength: Infinity,
              url: `https://memorybook-backend.herokuapp.com/api/users/get-user-info/${event.target.value}`,
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
              .then(function (response) {
                console.log(response.data.companyReviews.slice(-1)[0]);
                setComments(response.data.companyReviews.slice(-1)[0]);
                response.data.companyReviews.slice(-1)[0].map((values) => {
                  if (values.rating > 3) {
                    trueCommentCount++;
                  }

                  if (values.rating <= 3) {
                    falseCommentCount++;
                  }
                });
              })
              .catch(function (error) {
                console.log(error);
              });

            setTrueComment(trueCommentCount);
            setFalseComment(falseCommentCount);

            googlecomments.map((values) => {
              if (values.raiting > 3) {
                trueGoogleCount++;
              }

              if (values.raiting <= 3) {
                falseGoogleCount++;
              }
            });

            setGoogleTrueComment(trueGoogleCount);
            setGoogleFalseComment(falseGoogleCount);
          }}
        >
          {tesisler.map((values) => {
            if (values.userType == false) {
              return <MenuItem value={values._id}>{values.tesisName}</MenuItem>;
            }
          })}
        </Select>
      </FormControl>
      <ReactToPrint
        documentTitle="AwesomeFileName"
        trigger={() => (
          <button
            className="btn btn-warning"
            style={{ width: "10%", textTransform: "uppercase" }}
          >
            Yazdir
          </button>
        )}
        content={() => componentRef}
        pageStyle="print"
      />
      <div ref={(el) => (componentRef = el)}>
        <h2 className="text-center my-4">TripadVisor Yorum Raporları</h2>
        <div className="d-flex justify-content-center">
          <div className="w-25" style={{ marginRight: "10%" }}>
            <div className="card-body">
              <Pie
                data={{
                  labels: [`Olumsuz Yorumlar ${falseComment}`, `Olumlu Yorumlar ${trueComment}`],
                  datasets: [
                    {
                      color: "red",
                      label: "Yorum Sayisi",
                      data: [falseComment, trueComment],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-25">
            <div className="card-body">
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar % ${falseComment}`, `Olumlu Yorumlar % ${trueComment}`],
                  datasets: [
                    {
                      display: true,
                      label: "Yorum Yuzdesi",
                      data: [
                        percentageTripadCalculation(falseComment),
                        percentageTripadCalculation(trueComment),
                      ],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="text-center my-4">Google Yorum Raporları</h2>
        <div className="d-flex justify-content-center">
          <div className="w-25" style={{ marginRight: "10%" }}>
            <div className="card-body">
              <Pie
                data={{
                  labels: [`Olumsuz Yorumlar ${falseGoogleComment}`, `Olumlu Yorumlar ${trueGoogleComment}`],
                  datasets: [
                    {
                      display: true,
                      label: "Yorum Sayisi",
                      data: [falseGoogleComment, trueGoogleComment],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-25">
            <div className="card-body">
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${falseGoogleComment}`, `Olumlu Yorumlar ${trueGoogleComment}`],
                  datasets: [
                    {
                      label: "Yorum Yuzdesi",
                      data: [
                        percentageGoogleCalculation(falseGoogleComment),
                        percentageGoogleCalculation(trueGoogleComment),
                      ],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="text-center my-4">
          Tripadvisor aylık yorum raporu (30 Günlük)
        </h2>
        <div className="d-flex justify-content-center">
          <div className="w-25" style={{ marginRight: "10%" }}>
            <div className="card-body">
              <Pie
                data={{
                  labels: [`Olumsuz Yorumlar ${falseComment}`, `Olumlu Yorumlar ${trueComment}`],
                  datasets: [
                    {
                      color: "red",
                      label: "Yorum Sayisi",
                      data: [falseComment, trueComment],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-25">
            <div className="card-body">
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${falseComment}`, `Olumlu Yorumlar ${trueComment}`],
                  datasets: [
                    {
                      display: true,
                      label: "Yorum Yuzdesi",
                      data: [
                        percentageTripadCalculation(falseComment),
                        percentageTripadCalculation(trueComment),
                      ],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <h2 className="text-center my-4">
          Google aylık yorum raporu (30 Günlük)
        </h2>
        <div className="d-flex justify-content-center">
          <div className="w-25" style={{ marginRight: "10%" }}>
            <div className="card-body">
              <Pie
                data={{
                  labels: [`Olumsuz Yorumlar ${falseGoogleComment}`, `Olumlu Yorumlar ${trueGoogleComment}`],
                  datasets: [
                    {
                      display: true,
                      label: "Yorum Sayisi",
                      data: [falseGoogleComment, trueGoogleComment],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-25">
            <div className="card-body">
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${falseGoogleComment}`, `Olumlu Yorumlar ${trueGoogleComment}`],
                  datasets: [
                    {
                      label: "Yorum Yuzdesi",
                      data: [
                        percentageGoogleCalculation(falseGoogleComment),
                        percentageGoogleCalculation(trueGoogleComment),
                      ],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="w-25">
            <div className="card-body">
              <h2 style={{ textAlign: "center" }}>
                Genel Rating Ortalamasi ({rating})
              </h2>
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${100 - (100 * rating) / 5}`, `Olumlu Yorumlar ${(100 * rating) / 5}`],
                  datasets: [
                    {
                      label: "Yorum Yuzdesi",
                      data: [100 - (100 * rating) / 5, (100 * rating) / 5],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center flex-wrap">
          <div className="w-25" style={{ marginRight: "10%" }}>
            <div className="card-body">
              <h2 style={{ textAlign: "center" }}>Yemek</h2>
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${4}`, `Olumlu Yorumlar ${96}`],
                  datasets: [
                    {
                      label: "Yorum Yuzdesi",
                      data: [4, 96],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-25">
            <div className="card-body">
              <h2 style={{ textAlign: "center" }}>Oda</h2>
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${0}`, `Olumlu Yorumlar ${100}`],
                  datasets: [
                    {
                      label: "Yorum Yuzdesi",
                      data: [0, 100],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-25" style={{ marginLeft: "5%" }}>
            <div className="card-body">
              <h2 style={{ textAlign: "center", marginLeft: 5, }}>Temizlik</h2>
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${5}`, `Olumlu Yorumlar ${95}`],
                  datasets: [
                    {
                      label: "Yorum Yuzdesi",
                      data: [5, 95],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-25" style={{marginRight: "10%"}}>
            <div className="card-body">
              <h2 style={{ textAlign: "center" }}>Hizmet</h2>
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${0}`, `Olumlu Yorumlar ${100}`],
                  datasets: [
                    {
                      label: "Yorum Yuzdesi",
                      data: [0, 100],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-25">
            <div className="card-body">
              <h2 style={{ textAlign: "center" }}>Eğlence</h2>
              <Doughnut
                data={{
                  labels: [`Olumsuz Yorumlar ${25}`, `Olumlu Yorumlar ${75}`],
                  datasets: [
                    {
                      label: "Yorum Yuzdesi",
                      data: [25, 75],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(75, 192, 192, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
