import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../hooks/useAuth";

export default function Tesis() {
  const navigator = useNavigate();
  const [tesisler, setTesisler] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    axios({
      method: "get",
      maxBodyLength: Infinity,
      url: "https://memorybook-backend.herokuapp.com/api/users/",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(function (response) {
        setTesisler(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div className="w-100">
      <h1 className="text-center text-primary py-4">TESİS LISTELE</h1>
      <div className="row">
        <div className="col-md-12">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Tesis Adı</th>
                <th scope="col">Tesis Yetkili Adı</th>
                <th scope="col">Yetkili Mail</th>
                <th scope="col">Yetkili Tel</th>
                <th scope="col">İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {tesisler.map((value) => {
                if (!value.userType) {
                  return (
                    <tr>
                      <td>{value.tesisName}</td>
                      <td>{value.tesisYetkilisi}</td>
                      <td>{value.yetkiliMail}</td>
                      <td>{value.yetkiliTelNo}</td>
                      <td scope="row">
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            axios({
                              method: "delete",
                              maxBodyLength: Infinity,
                              url: `https://memorybook-backend.herokuapp.com/api/users/delete-user/${value._id}`,
                              headers: {
                                Authorization: `Bearer ${user.token}`,
                              },
                            })
                              .then(function (response) {
                                console.log(JSON.stringify(response.data));
                                Swal.fire({
                                  position: "bottom-end",
                                  icon: "success",
                                  title: "Tesis başarılı bir şekilde silindi",
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                                setTimeout(() => {
                                  navigator(0);
                                }, 1600);
                              })
                              .catch(function (error) {
                                console.log(error);
                              });
                          }}
                        >
                          Sil
                        </button>
                        <a
                          href={`/tesis-goruntule/${value._id}`}
                          className="btn btn-primary"
                          style={{ marginLeft: 5 }}
                        >
                          Tesis Detay
                        </a>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
