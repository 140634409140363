import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import Dashboard from "./pages/Dashboard";
import Tesis from "./pages/Tesis";
import Login from "./pages/Login";
import TesisDetail from "./pages/TesisDetail";
import TesisAdd from "./pages/TesisAdd";
import Reports from "./pages/Reports";
import Raffle from "./pages/Raffle";

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Dashboard />}>
            <Route path="tesis-islemleri" element={<Tesis />} />
            <Route path="tesis-goruntule/:id" element={<TesisDetail />} />
            <Route path="tesis-ekle" element={<TesisAdd />} />
            <Route path="raporlar" element={<Reports />} />
            <Route path="cekilis-yap" element={<Raffle />} />
          </Route>
          <Route path="login" element={<Login />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
