import React from "react";
import {
  Button,
  FilledInput,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function TesisAdd() {
  const navigator = useNavigate();
  const [tesisinTamAdi, setTesisinTamAdi] = useState("");
  const [themeID, setThemeID] = useState(0);
  const [tesisinYetkilisi, setTesisinYetkilisi] = useState("");
  const [yetkiliMail, setYetkiliMail] = useState("");
  const [yetkiliTelNo, setYetkiliTelNo] = useState("");
  const [password, setPassword] = useState("");
  const [tripadvisor, setTripadvisor] = useState("");
  const [holydaycheck, setHolydaycheck] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [google, setGoogle] = useState("");
  const [booking, setBooking] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid;

    if (themeID == 0) {
      isValid = false;
    } else if (tesisinYetkilisi == "") {
      isValid = false;
    } else if (selectedDate.target.value == "") {
      isValid = false;
    } else if (yetkiliMail == "") {
      isValid = false;
    } else if (yetkiliTelNo == "") {
      isValid = false;
    } else if (password == "") {
      isValid = false;
    } else {
      isValid = true;
    }

    if (isValid) {
      await axios({
        method: "post",
        maxBodyLength: Infinity,
        url: "https://memorybook-backend.herokuapp.com/api/users/register",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          QRLinks: [tripadvisor, holydaycheck, google, booking],
          themeID: themeID,
          tesisName: tesisinTamAdi,
          tesisYetkilisi: tesisinYetkilisi,
          yetkiliMail: yetkiliMail,
          yetkiliTelNo: yetkiliTelNo,
          hediyeTatilTarih: selectedDate.target.value,
          password: password,
        }),
      })
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Tesis başarılı bir şekilde kaydedildi",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            navigator(0);
          }, 1600);
        })
        .catch(function (error) {
          Swal.fire({
            icon: "error",
            title: "Tesis Hatası",
            text: error.response.data.message,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Form Hatası",
        text: "hiçbir veri boş geçilemez",
      });
    }
  };

  return (
    <div className="w-100 d-flex align-items-center flex-column">
      <h1 className="text-center text-primary py-4">TESİS EKLE</h1>
      <div className="flex-colmn col-md-8">
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-basic"
            label="Tesisin Tam adı"
            variant="outlined"
            className="w-100"
            value={tesisinTamAdi}
            onChange={(text) => setTesisinTamAdi(text.target.value)}
            required
          />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="Modern Tasarım"
              control={
                <Radio
                  name="themeID"
                  value={4}
                  onChange={() => setThemeID(4)}
                />
              }
              label="Alaturka Tasarım"
            />
            <FormControlLabel
              value="Modern Tasarım"
              control={
                <Radio
                  name="themeID"
                  value={3}
                  onChange={() => setThemeID(3)}
                />
              }
              label="Modern Tasarım"
            />
            <FormControlLabel
              value="Defter Tasarım"
              control={
                <Radio
                  name="themeID"
                  value={2}
                  onChange={() => setThemeID(2)}
                />
              }
              label="Defter Tasarım"
            />
            <FormControlLabel
              value="Standart Tasarım"
              control={
                <Radio
                  name="themeID"
                  value={1}
                  onChange={() => setThemeID(1)}
                />
              }
              label="Standart Tasarım"
            />
          </RadioGroup>
          <TextField
            id="outlined-basic"
            label="Tesisin Yetkili Tam Adı"
            variant="outlined"
            className="w-100 my-3"
            value={tesisinYetkilisi}
            onChange={(text) => setTesisinYetkilisi(text.target.value)}
            required
          />
          <TextField
            type={"email"}
            id="outlined-basic"
            label="Yetkili Mail"
            variant="outlined"
            className="w-100 "
            value={yetkiliMail}
            onChange={(text) => setYetkiliMail(text.target.value)}
            required
          />
          <TextField
            id="outlined-basic"
            label="Yetkili Tel No"
            variant="outlined"
            className="w-100 my-3"
            value={yetkiliTelNo}
            onChange={(text) => setYetkiliTelNo(text.target.value)}
            required
          />
          <TextField
            id="outlined-basic"
            label="Şifre"
            variant="outlined"
            className="w-100"
            type={"password"}
            value={password}
            onChange={(text) => setPassword(text.target.value)}
            required
          />
          <TextField
            id="date"
            label="Hediye Tatil Tarihi"
            type="date"
            defaultValue={selectedDate}
            onChange={(text) => setSelectedDate(text)}
            className="w-100 mt-3"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="outlined-basic"
            label="TRIPADVISOR"
            variant="outlined"
            className="w-100 my-3"
            value={tripadvisor}
            onChange={(text) => setTripadvisor(text.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="HOLYDAYCHECK"
            variant="outlined"
            className="w-100"
            value={holydaycheck}
            onChange={(text) => setHolydaycheck(text.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="GOOGLE"
            variant="outlined"
            className="w-100 my-3"
            value={google}
            onChange={(text) => setGoogle(text.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="BOOKING"
            variant="outlined"
            className="w-100"
            value={booking}
            onChange={(text) => setBooking(text.target.value)}
          />
          <div className="w-100 d-flex justify-content-center my-3">
            <Button
              type="submit"
              variant="contained"
              color="success"
              className="mt-3 w-25 py-2"
            >
              Ekle
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
