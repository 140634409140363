import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  Avatar,
  Backdrop,
  CardActionArea,
  CardActions,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import * as qs from "qs";
import QRCode from "react-qr-code";
import Swal from "sweetalert2";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid } from "@mui/x-data-grid";
import EditTesis from "./EditTesis";

export default function TesisDetail() {
  let { id } = useParams();
  let { user } = useAuth();
  const [dbData, setData] = React.useState([]);
  const [QRlinks, SetQrLinks] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [googlecomments, setGoogleComments] = React.useState([
    {
      id: 0,
      title: "Nilgün Özyurt",
      imgUrl:
        "https://lh3.googleusercontent.com/a-/ACB-R5SS_huOU9hL2E5oY5GmPL3oSgs4R_pND9CvLHx6BQ=s40-c-c0x00000000-cc-rp-mo-br100",
      comment:
        "Tek kelime ile kusursuzdu tatilimiz. Ben ki detaycı ve titiz biri olarak bunu söylüyorsam gerçekten öyledir. İlk defa bir işletmede kusur bulamadım. İster ailenizle ister arkadaşlarınızla ister tek başınıza gidin hepsine uygun. Gerek fiziki olarak otel şartlarının ve odaların modernliği gerekse yemekler ve hijyen olarak tazelik ve çeşitlilik çok şahaneydi. Kalabalık olmasına rağmen asla o kalabalığı hissetmiyorsunuz çünkü hiçbir hizmet aksamıyor. Vegan ve şekersiz yemek çeşitleri de mevcut benim gibi rahatsızlığı olanlar için. Bayat hiçbir şey görmedik bir gün bile. Deniz ve havuz koşulları ve akşam müzik etkinlikleri de çok keyifliydi. Ama enn önemlisi personel. Genç ve güler yüzlü bir ekip. Her an her sorunla ilgileniyorlar. Özellikle garsonlar İbrahim ve Umut arkadaşlara çok teşekkürler(yöneticiler tarafından takdiri hakediyor hepsi). Çok zariflerdi hepsi. Kesinlikle başka şehire gitsem yine tui blue otellerinde kalmak isteyeceğiz artık. Teşekkürler ve tebrikler tüm ekibe.",
      raiting: 5,
    },
    {
      id: 1,
      title: `Nazan Aktas`,
      imgUrl:
        "https://lh3.googleusercontent.com/a-/ACB-R5RbAiTLELHbZxVGz5STckYP4lmZ8PrPOBOH06aZGg=s40-c-c0x00000000-cc-rp-mo-br100",
      comment: `Otelde ikinci kez bulunuyoruz. Sunulan tüm hizmetler oldukça başarılı. Otel yönetiminin ilkeli, gayretli ve çalışanları ile birebir uygulamanın içinde olduğu açıkça kendini gösteriyor. Çalışan dostu, anlayışlı yönetim, çalışanların sorumluluklarını keyifle yerine getirmelerine,
      en iyi hizmeti vermeye gayret etmelerine yansımış. Otelde gün içerisinde yapacağınız çok çeşitli etkinlikler (okçuluk, bahçe gezisi, zumba, yoga vb.) profesyonelce planmış oldukça keyifli ve BLUE APP uygulaması ile kolayca organize edilebiliyor. Otelde kendimizi evimizde gibi hissettik. Taleplerimize en ideal çözümü hızlı bir şekilde samimiyetle sağladılar. Konaklamamızda bize yardımcı olan Ayla Hanımı samimi iletişimi ve hızlı organizasyon becerisi için tebrik ederiz. Otelin üst düzey yönetiminin her an sahada olması bizim için memnuniyet vericiydi.Otel müdürü Mehmet Bey, Ayla Hanım ve Zeynep Hanım başta olmak üzere tüm çalışanlara misafirperverlikleri ve sundukları kaliteli hizmet için çok teşekkür ederiz.
      NAZAN , AKIN , ECE AKTAŞ`,
      raiting: 5,
    },
    {
      id: 2,
      imgUrl: `https://lh3.googleusercontent.com/a-/ACB-R5QtJpbydJaRy1zu8nc4wJNSQo4Ms3OfdPFuZb4M1A=s40-c-c0x00000000-cc-rp-mo-ba3-br100`,
      comment: `Tesisten genel olarak mutlu ayrıldık.
      Pozitif tarafları: Personelin güleryüzü ve yardımseverliliği, yemek çeşitliliği, temizliği, odaların bakımlı olması ve peyzaj güzelliği.
      Negatif tarafları: çok sivrisinek vardı çok. Alkol çeşitliliğinin daha fazla olmasını tercih ederdim bazı kokteyller ücretli idi. Zaten yerli içki ve meşrubat ile yapıyorsunuz neden ücret? Çok saçma...  ithal içkilerden ücret talep etmenizi fiyat performans olarak eleştirmiyorum. Çocuk dostu bir otel olduğundan çok fazla çocuk bebek vardı. Bazen kendinizi kreşte gibi hissediyorsunuz. Çocuğu olmayan çiftler bu nedenle sezonda bir kez daha düşünmeli.
      Genel olarak tesis temiz, bakımlı ve kaliteliydi.`,
      raiting: 5,
    },
    {
      id: 3,
      imgUrl: `https://lh3.googleusercontent.com/a-/ACB-R5RJUyc1zUi5vo6J5CE8LJFVhOJJvgFQAY10MWV8=s40-c-c0x00000000-cc-rp-mo-br100`,
      comment: `Otelenizde geldim memnun kaldım odalar temiz personel güler yüzlü ilgileniliyor restorant da Ekrem bey e teşekür ediyorum bizimle çok ilgilendi otele giderseniz restorana Ekrem i mutlaka bulun otel müdürü Mehmet bey e de teşekür ediyoruz`,
      raiting: 5,
    },
    {
      id: 4,
      title: `Fatma Gülen`,
      imgUrl:
        "https://media-cdn.tripadvisor.com/media/photo-l/1a/f6/eb/e3/default-avatar-2020-59.jpg",
      comment: `Merhabalar, az önce check out yaptığımız 5 gecelik tatilimizde gönlümüz sizlerle kaldı, uzun zamandır yapamadığımız o huzurlu tatili bizlere sunduğunuz için çok teşekkür ederiz.
    Otelin tüm çalışanlarının güler yüzü ve sizlere verdikleri hizmetle mutlulukları hissediliyor. Tüm emekleriniz için Tui Blue Palm Garden takımına teşekkürlerimizi sunarız.
    6 yıldız verebilsek verirdik tüm aile olarak hiçbir konudan memnuniyetsiz olmadık. Yemeklerin lezzeti ve çeşitliliği, etkinliklerin kalitesi, çocuk kulübünün çoğu otelden (daha pahalı oteller de var bu bahsettiğim çoğu otel kısmında) çok çok daha iyi olması, çocuğunuzu gönül rahatlığıyla emanet edebileceğiniz sevecen ve güvenilir abiler ve ablalar bizim için çok memnuniyet verici oldu.
    Temizlik çok güzel her gün tertemiz odamıza geldik, ortamın nezih olması nedeniyle huzurlu ve sakin bir tatildi. Gün içindeki etkinlikler çok keyifli tek sorun çok sıcak ama bu da Side'nin özelliği zaten sıcak olmayınca da tatil yaptığını hissedemiyor insan.
    Oturup yorum yazacak kadar etkilendik ve çok beğendik herşeyi.
    Tesis yüz üzerinden zaten 99 tek olarak bir tavsiye vermek istiyorum o da (biz suite odada kalıyorduk) arka odadaki klimaların direkt olarak yatağa üflemesi. Biz bu konudan dolayı zorlanmadık çünkü odaların ısı yalıtımı çok iyi olduğundan zaten soğuk kalıyor sabaha kadar odalar (alt katta kaldık bu nedenle üst katları bilemiyorum) ama otel tekrar yenilenir veya odaların dekoru değişir ise bu konuyu da dikkate alabilirsiniz bence çok çok iyi olacaktır çünkü yatağa klima üflediğinde çok üşünebilir biz açamadık şahsen. Sadece bunu belirtebiliriz sizlere faydalı olması adına. Bunun dışında harika bir 5gece geçirdik. Muhakkak tekrar görüşeceğiz, herşey için tekrar çok teşekkür eder, iyi çalışmalar dileriz.
    `,
      raiting: 5,
    },
    {
      id: 5,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      title: `ERAY YIGIT`,
      comment: `Bu otelde ilk kez tatil yaptık. Çok fazla iyi yorum okumuştum gitmeden. O yorumların hepsi haklı ve doğruymuş.Otele girişte gösterilen ilgi alaka ve güleryüz sonrası doğru bir seçim yaptığımı anladım. Tam bir aile oteli. Odalar, restoranlar, ortak alanlar, sahil tertemiz pırıl pırıl (havuzu kullanmadığımız için yorum yapamıyorum). Çalışanların ilgisi güler yüzü koşuşturmaları üst seviyede. Yemekleri lezzetleri kullanılan malzemeleri çok iyi. Şefler ustalar işinin yemeklerinin başında. Yerken aman dokunmasın diye terreddüt etmiyorsunuz (Alkol kullanmadığım için o konuda yorum yapamıyorum). Tatil köyü konseptinde.Koca koca binalar asansörde sıra beklemeler uzun koridorlarda yürümeler yok. Gece eğlenceleri organizasyonları nedir bilmiyorum ses geliyordu ama katılmadık:) birazda kafa dinlemeye gittik çünkü. Denizi biraz erken derinleşiyor ve çakıllı. Ama çakıl demek temizlik demek bence. Dalgayla birlikte kumlu sahiller çekilmez oluyor ama yinede tercih meselesi. Dalga derinlik çakıl bir buçuk yaşında oğlumuzla bizi mağdur etmedi öyle diyeyim.Çokta keyif aldık. Bir çok otele gittim bırakın Müdürü çalışanlarını bile göremiyorsunuz. Otelin genel müdürü sürekli işinin başında ve sahada. Neredeyse her misafirle tek tek ilgileniyor. İnanın ben olsam o kadar yapamazdım. Buradan tüm Palm garden çalışanlarına teşekkür ediyorum. önümüzdeki yıl nasip olursa tatilimizin adresi yine aynı olacak.
      `,
      raiting: 5,
    },
    {
      id: 6,
      title: `Eray`,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      comment: `Tek kelime ile kusursuzdu tatilimiz. Ben ki detaycı ve titiz biri olarak bunu söylüyorsam gerçekten öyledir. İlk defa bir işletmede kusur bulamadım. İster ailenizle ister arkadaşlarınızla ister tek başınıza gidin hepsine uygun. Gerek fiziki olarak otel şartlarının ve odaların modernliği gerekse yemekler ve hijyen olarak tazelik ve çeşitlilik çok şahaneydi. Kalabalık olmasına rağmen asla o kalabalığı hissetmiyorsunuz çünkü hiçbir hizmet aksamıyor. Vegan ve şekersiz yemek çeşitleri de mevcut benim gibi rahatsızlığı olanlar için. Bayat hiçbir şey görmedik bir gün bile. Deniz ve havuz koşulları ve akşam müzik etkinlikleri de çok keyifliydi. Ama enn önemlisi personel. Genç ve güler yüzlü bir ekip. Her an her sorunla ilgileniyorlar. Özellikle garsonlar İbrahim ve Umut arkadaşlara çok teşekkürler(yöneticiler tarafından takdiri hakediyor hepsi). Çok zariflerdi hepsi. Kesinlikle başka şehire gitsem yine tui blue otellerinde kalmak isteyeceğiz artık. Teşekkürler ve tebrikler tüm ekibe.`,
      raiting: 5,
    },
    {
      id: 7,
      title: `SAMING`,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      comment: `Lafı çok uzatmadan titiz biri olarak anlatayım; hemen hemen her yönüyle mükemmel bir tesis. Başta, Genel Müdür Mehmet Çubukçuoğlu, Halkla İlişkiler Müdürü Cansu Hanım ve tüm yönetimi tebrik ediyor ayrı ayrı teşekkürlerimi iletiyorum. Buradan ve tatil sitelerinden yapılan yorumlara bakarak ve merâk ederek gitmeye karar verdik ve iyi ki gitmişiz, abartı gibi düşünülen her şey doğru çıktı. Fiziksel imkanlar yeterli, yemekler ve tatlılar çok iyi, yemek salonunda karmaşa yok, sakin ve her zaman rahatça yer oluyor, hemen ne istediğiniz soruluyor, personel özenle seçilmiş ve eğitim görmüş, her yer temiz, bir çok yerde garson sizden kaçar, göz teması kurmaz, burada sizi takip ediyorlar, tüm çalışanlar çok candan ve kibarlar. Doğa ve peyzaj çok iyi, havuz biraz sıcak ama sorun olmadı, hem dinlendik hem eğlendik. Daha çok şey yazabilirim ama yazdıklarım her şeyi özetlemiştir diye düşünüyorum. Herkese tavsiye ederim.`,
      raiting: 5,
    },
    {
      id: 8,
      title: `antikgezgin`,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      comment: `Otelde ikinci kez bulunuyoruz. Sunulan tüm hizmetler oldukça başarılı. Otel yönetiminin ilkeli, gayretli ve çalışanları ile birebir uygulamanın içinde olduğu açıkça kendini gösteriyor. Çalışan dostu, anlayışlı yönetim, çalışanların sorumluluklarını keyifle yerine getirmelerine,
      en iyi hizmeti vermeye gayret etmelerine yansımış. Otelde gün içerisinde yapacağınız çok çeşitli etkinlikler (okçuluk, bahçe gezisi, zumba, yoga vb.) profesyonelce planmış oldukça keyifli ve BLUE APP uygulaması ile kolayca organize edilebiliyor. Otelde kendimizi evimizde gibi hissettik. Taleplerimize en ideal çözümü hızlı bir şekilde samimiyetle sağladılar. Konaklamamızda bize yardımcı olan Ayla Hanımı samimi iletişimi ve hızlı organizasyon becerisi için tebrik ederiz. Otelin üst düzey yönetiminin her an sahada olması bizim için memnuniyet vericiydi.Otel müdürü Mehmet Bey, Ayla Hanım ve Zeynep Hanım başta olmak üzere tüm çalışanlara misafirperverlikleri ve sundukları kaliteli hizmet için çok teşekkür ederiz.
      NAZAN , AKIN , ECE AKTAŞ`,
      raiting: 5,
    },
    {
      id: 9,
      title: `seraptapan`,
      imgUrl: "https://cdn.yerelrehber.com/uploads/avatar/no-avatar.png",
      comment: `Muhteşem, tek kelimeyle kusursuz bir tatil geçirdik, personelin ilgi alakası, hotelin temizliği, ev tadında, huzurunda bir tatildi, tüm ekibe tapan ailesi olarak teşekkür ederiz.`,
      raiting: 5,
    },
  ]);
  const [email, setEmail] = React.useState([]);
  const [load, isLoad] = React.useState(false);
  const [age, setAge] = React.useState("");
  const [googleRaiting, setGoogleRaiting] = React.useState("");
  const navigate = useNavigate();

  const handleChangeCheckbox = (event) => {
    setAge(event.target.value);
  };

  const handleChangeGoogleCheckbox = (event) => {
    setGoogleRaiting(event.target.value);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const getData = async () => {
    isLoad(true);
    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://memorybook-backend.herokuapp.com/api/users/get-user-info/${id}`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(function (response) {
        setData([response.data]);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://memorybook-backend.herokuapp.com/api/users/get-user-info/${id}`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(function (response) {
        SetQrLinks(response.data.QRLinks);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://memorybook-backend.herokuapp.com/api/users/get-user-info/${id}`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(function (response) {
        if (response.data.companyReviews.length > 0)
          setComments(response.data.companyReviews.slice(-1)[0]);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: "https://memorybook-backend.herokuapp.com/api/guestMail/getAllGuestMail",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(function (response) {
        let newArr = [];
        response.data.map((values) => {
          newArr.push({ id: values._id, dataEmail: values.mail });
        });
        setEmail(newArr);
      })
      .catch(function (error) {
        console.log(error);
      });

    isLoad(false);
  };

  const tripadVisorRequest = async (getText) => {
    let getId;
    let reviews = [];
    isLoad(true);
    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.content.tripadvisor.com/api/v1/location/search?key=7AA7F9B0D4814ECA85D9DF2FAF74935F&searchQuery=${getText}&language=tr`,
      headers: {},
    })
      .then(function (response) {
        getId = response.data.data[0].location_id;
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.content.tripadvisor.com/api/v1/location/${getId}/reviews?key=7AA7F9B0D4814ECA85D9DF2FAF74935F&language=tr`,
      headers: {},
    })
      .then(function (response) {
        response.data.data.forEach((element) => {
          reviews.push(element);
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://memorybook-backend.herokuapp.com/api/tripadvisorReview/getReviewByCompanyId/${id}`,
      headers: {},
    })
      .then(function (response) {
        console.log(response.data.tripadvisorReview[0]);
        for (let i = 0; i < response.data.tripadvisorReview[0].length; i++) {
          if (response.data.tripadvisorReview[0][i].id != reviews[i].id) {
            reviews.push(response.data.tripadvisorReview[0][i]);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "put",
      maxBodyLength: Infinity,
      url: `https://memorybook-backend.herokuapp.com/api/users/add-reviews/${id}`,
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        companyReviews: reviews,
      }),
    })
      .then(function (response) {
        console.log(response.data);
        isLoad(false);
        Swal.fire({
          position: "bottom-end",
          icon: "success",
          title: "Yorumlar başarılı bir şekilde getirildi",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          navigate(0);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteUser = async (id) => {
    axios({
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://memorybook-backend.herokuapp.com/api/users/delete-user/${id}`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {load ? (
        <div className="d-flex justify-content-center py-4">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Tesis Bilgileri
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Tesis Bilgileri Güncelle
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              {dbData.length != 0
                ? dbData.map((values) => {
                    return (
                      <>
                        <div className="w-100 d-flex justify-content-center align-items-center my-4">
                          {QRlinks.map((text) => {
                            if (text.length != 0 && text.search(".") != 0) {
                              return (
                                <div
                                  style={{
                                    height: "auto",
                                    margin: "0 auto",
                                    maxWidth: 250,
                                    width: "100%",
                                  }}
                                >
                                  <h3
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "uppercase",
                                      textAlign: "center",
                                    }}
                                  >
                                    {text.length != 0 && text.search(".") != 0
                                      ? new URL(text).hostname.split(".")[1]
                                      : null}
                                  </h3>
                                  <a href={text} target="_blank">
                                    <QRCode
                                      size={256}
                                      style={{
                                        height: "auto",
                                        maxWidth: "100%",
                                        width: "100%",
                                      }}
                                      value={text}
                                      viewBox={`0 0 256 256`}
                                    />
                                  </a>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div
                          className="w-100 d-flex justify-content-center flex-wrap my-3"
                          key={values.id}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              tripadVisorRequest(values.tesisName);
                            }}
                            sx={{
                              fontSize: 12,
                            }}
                          >
                            TripadVisor Verilerini Güncelle
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ marginLeft: 1, fontSize: 12 }}
                            disabled
                          >
                            Holiydaycheck Verilerini Güncelle
                          </Button>
                          <Button
                            variant="contained"
                            onClick={async () => {
                              isLoad(true);
                              await delay(2000);
                              isLoad(false);
                              Swal.fire({
                                position: "bottom-end",
                                icon: "success",
                                title:
                                  "Yorumlar başarılı bir şekilde getirildi",
                                showConfirmButton: false,
                                timer: 1500,
                              }).then(() => {
                                navigate(0);
                              });
                            }}
                            sx={{ marginLeft: 1, fontSize: 12 }}
                          >
                            Google Verilerini Güncelle
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ marginLeft: 1, fontSize: 12 }}
                            disabled
                          >
                            Booking Verilerini Güncelle
                          </Button>
                        </div>
                      </>
                    );
                  })
                : null}
              <div className="mt-4">
                {dbData.length > 0 ? (
                  <div className="d-flex flex-row">
                    <Card
                      sx={{
                        width: "40%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <CardContent>
                        <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                          {dbData[0].tesisYetkilisi}
                        </Typography>
                        <Typography
                          sx={{ textAlign: "center" }}
                          color="text.secondary"
                        >
                          {dbData[0].tesisName}
                        </Typography>
                      </CardContent>
                      <CardActions className="mb-3">
                        <Button
                          variant="contained"
                          color="error"
                          size="medium"
                          onClick={() => {
                            const swalWithBootstrapButtons = Swal.mixin({
                              customClass: {
                                confirmButton: "btn btn-success",
                                cancelButton: "btn btn-danger",
                              },
                              buttonsStyling: false,
                            });

                            swalWithBootstrapButtons
                              .fire({
                                title:
                                  "Silme işlemi yapmak istediğinden emin misin ?",
                                text: "Yaptığın değişiklikler geri alınamaz veya kurtarılamaz",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Sil",
                                cancelButtonText: "İptal",
                                reverseButtons: true,
                              })
                              .then((result) => {
                                if (result.isConfirmed) {
                                  deleteUser(user._id);
                                  swalWithBootstrapButtons.fire(
                                    "Silindi!",
                                    "Tesisiniz başarılı bir şekilde silindi.",
                                    "success"
                                  );
                                  navigate("/tesis-islemleri");
                                }
                              });
                          }}
                        >
                          <DeleteIcon />
                          Sil
                        </Button>

                        <Button
                          variant="contained"
                          color={dbData[0].isBanned ? "success" : "warning"}
                          size="medium"
                          onClick={async () => {
                            var myHeaders = new Headers();
                            myHeaders.append(
                              "Content-Type",
                              "application/json"
                            );
                            myHeaders.append(
                              "Authorization",
                              `Bearer ${user.token}`
                            );

                            await fetch(
                              `https://memorybook-backend.herokuapp.com/api/users/user-update/${id}`,
                              {
                                method: "PUT",
                                headers: myHeaders,
                                body: JSON.stringify({
                                  isBanned: !dbData[0].isBanned,
                                }),
                                redirect: "follow",
                              }
                            )
                              .then((response) => response.text())
                              .then((result) => {
                                console.log(JSON.parse(result));
                                if (JSON.parse(result).status == 200) {
                                  Swal.fire({
                                    position: "bottom-end",
                                    icon: "success",
                                    title: `Başarılı bir şekilde ${
                                      dbData[0].isBanned ? "aktif" : "pasif"
                                    }  hale getirildi`,
                                    showConfirmButton: true,
                                    timer: 1500,
                                  }).then(() => {
                                    navigate(0);
                                  });
                                }
                              })
                              .catch((error) => console.log("error", error));
                          }}
                        >
                          {dbData[0].isBanned ? (
                            <CheckIcon style={{ marginRight: 5 }} />
                          ) : (
                            <BlockIcon style={{ marginRight: 5 }} />
                          )}
                          {dbData[0].isBanned ? "Aktif" : "Pasif"}
                        </Button>
                      </CardActions>
                    </Card>
                    <Card sx={{ width: "100%", margin: "0 1.5%" }}>
                      <CardContent>
                        <div className="row w-100">
                          <div className="col-md-4">
                            <span
                              style={{
                                fontSize: 18,
                                fontWeight: "bold",
                              }}
                            >
                              Yetkili Mail:
                            </span>
                          </div>
                          <div className="col-md-8">
                            <span style={{ fontSize: 18, color: "gray" }}>
                              {dbData[0].yetkiliMail}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="row w-100">
                          <div className="col-md-4">
                            <span
                              style={{
                                fontSize: 18,
                                fontWeight: "bold",
                              }}
                            >
                              Yetkili Telefon No:
                            </span>
                          </div>
                          <div className="col-md-8">
                            <span style={{ fontSize: 18, color: "gray" }}>
                              {dbData[0].yetkiliTelNo}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="row w-100">
                          <div className="col-md-4">
                            <span
                              style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                textAlign: "right",
                              }}
                            >
                              Hediye Tatil Tarihi:
                            </span>
                          </div>
                          <div className="col-md-8">
                            <span style={{ fontSize: 18, color: "gray" }}>
                              {dbData[0].hediyeTatilTarih}
                            </span>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                ) : null}
              </div>
              <div>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      TripadVisor
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      Son 5 yorum
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {comments.map((values) => {
                      return (
                        <Card
                          sx={{
                            width: "100%",
                            marginTop: "1%",
                            marginBottom: "1%",
                          }}
                        >
                          <CardContent>
                            <div className="d-flex align-items-center">
                              <Avatar
                                alt="Remy Sharp"
                                src={values.user.avatar.small.url}
                                sx={{ width: 56, height: 56 }}
                              />
                              <Typography
                                variant="h5"
                                component="div"
                                sx={{ marginLeft: "1%" }}
                              >
                                {values.user.username}
                              </Typography>
                            </div>
                            <Typography variant="body2" className="my-2">
                              {values.text}
                              <br />
                            </Typography>
                            <Typography>Raiting: {values.rating}</Typography>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Google
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      Son 5 yorum
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {googlecomments.map((values) => {
                      return (
                        <Card
                          sx={{
                            width: "100%",
                            marginTop: "1%",
                            marginBottom: "1%",
                          }}
                        >
                          <CardContent>
                            <div className="d-flex align-items-center">
                              <img
                                src={values.imgUrl}
                                style={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: "100%",
                                }}
                              />
                              <Typography
                                variant="h5"
                                component="div"
                                sx={{ marginLeft: "1%", fontWeight: "bold" }}
                              >
                                {values.title}
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              className="my-4"
                              style={{
                                fontWeight: 400,
                              }}
                            >
                              {values.comment}
                              <br />
                            </Typography>
                            <Typography
                              variant="body2"
                              className="my-2"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              Raiting {values.raiting}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Google
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      İstatistikler
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Rating
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={googleRaiting}
                        label="Raiting flitre"
                        onChange={handleChangeGoogleCheckbox}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </Select>
                    </FormControl>
                    {googlecomments.map((values) => {
                      if (values.raiting == googleRaiting) {
                        return (
                          <Card
                            sx={{
                              width: "100%",
                              marginTop: "1%",
                              marginBottom: "1%",
                            }}
                          >
                            <CardContent>
                              <div className="d-flex align-items-center">
                                <img
                                  src={values.imgUrl}
                                  style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: "100%",
                                  }}
                                />
                                <Typography
                                  variant="h5"
                                  component="div"
                                  sx={{ marginLeft: "1%", fontWeight: "bold" }}
                                >
                                  {values.title}
                                </Typography>
                              </div>
                              <Typography
                                variant="body2"
                                className="my-4"
                                style={{
                                  fontWeight: 400,
                                }}
                              >
                                {values.comment}
                                <br />
                              </Typography>
                              <Typography
                                variant="body2"
                                className="my-2"
                                style={{ fontWeight: "bold", fontSize: 16 }}
                              >
                                Raiting {values.raiting}
                              </Typography>
                            </CardContent>
                          </Card>
                        );
                      }
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-header"
                    id="panel2bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      TripadVisor
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      İstatislikler
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Rating
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Raiting flitre"
                        onChange={handleChangeCheckbox}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </Select>
                    </FormControl>
                    {comments.map((values) => {
                      if (values.rating == age) {
                        return (
                          <>
                            <Card
                              sx={{
                                width: "100%",
                                marginTop: "1%",
                                marginBottom: "1%",
                              }}
                            >
                              <CardContent>
                                <div className="d-flex align-items-center">
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={values.user.avatar.small.url}
                                    sx={{ width: 56, height: 56 }}
                                  />
                                  <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ marginLeft: "1%" }}
                                  >
                                    {values.user.username}
                                  </Typography>
                                </div>
                                <Typography variant="body2" className="my-2">
                                  {values.text}
                                  <br />
                                </Typography>
                                <Typography>
                                  Raiting: {values.rating}
                                </Typography>
                              </CardContent>
                            </Card>
                          </>
                        );
                      }
                    })}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-header"
                    id="panel2bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Memorybook Üzerinden Yorum Yapan Kullanıcılar
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={email}
                        columns={[
                          {
                            field: "dataEmail",
                            headerName: "mail",
                            width: "100%",
                          },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <EditTesis />
            </div>
          </div>
        </>
      )}
    </>
  );
}
