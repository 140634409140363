import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import * as qs from "qs";
import Swal from "sweetalert2";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Raffle() {
  const [email, setEmail] = useState([]);
  const [tesisName, setTesisNames] = useState([]);
  const [randomNumber, setRandomNumber] = useState(0);
  const [isButton, setButton] = useState(false);
  const [tesisId, setTesisId] = useState("");
  const [isLoad, setLoad] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChange = (event) => {
    setButton(false);
    setTesisId(event.target.value);

    axios({
      method: "get",
      maxBodyLength: Infinity,
      url: `https://memorybook-backend.herokuapp.com/api/guestMail/getByTesisId/${event.target.value}`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((response) => {
        console.log(
          JSON.parse(JSON.stringify(response.data)).tesisinMusteriMailleri
        );
        setEmail(
          JSON.parse(JSON.stringify(response.data)).tesisinMusteriMailleri
        );

        if (
          JSON.parse(JSON.stringify(response.data)).tesisinMusteriMailleri
            .length == 0
        ) {
          Swal.fire({
            title: "UYARI!",
            text: "Bu tesiste henüz yorum yapan bulunmamaktadır! lütfen başka bir tesis seçin",
            timer: 2000,
          });

          setButtonDisabled(true);
        } else {
          setButtonDisabled(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let { user } = useAuth();

  const getData = async () => {
    setLoad(true);
    await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: "https://memorybook-backend.herokuapp.com/api/users/",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((response) => {
        setTesisNames(JSON.parse(JSON.stringify(response.data)));
      })
      .catch((error) => {
        console.log(error);
      });
    setLoad(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-100 d-flex justify-content-center flex-column">
      {isLoad ? (
        <>Yükleniyor...</>
      ) : (
        <>
          <h1 className="text-primary text-center my-4">ÇEKİLİŞ YAP</h1>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tesisId}
            label="Age"
            onChange={handleChange}
          >
            {tesisName.map((item) => {
              return <MenuItem value={item._id}>{item.tesisName}</MenuItem>;
            })}
          </Select>
          <button
            className="btn btn-primary mt-3"
            onClick={() => {
              setRandomNumber(Math.floor(Math.random() * email.length) - 1);
              setButton(true);
            }}
            disabled={buttonDisabled}
          >
            Çekiliş Yap
          </button>

          {isButton ? (
            <>
              <h3 className="text-center my-4 text-danger">
                {email.length > 0
                  ? email[randomNumber == -1 ? randomNumber + 1 : randomNumber]
                      .mail
                  : null}
              </h3>
              <button
                className="btn btn-warning w-25 text-dark"
                onClick={() => {
                  Swal.fire({
                    title: "Çekiliş sonucunu onaylıyor musun ?",
                    text: "Çekılış sonucunu onayladıktan sonra çekilişi kazanan emaile mail gönderilecektir.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Evet, Gönder!",
                    cancelButtonText: "iptal",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await axios
                        .request({
                          method: "post",
                          maxBodyLength: Infinity,
                          url: "https://memorybook-backend.herokuapp.com/api/mailSender/send",
                          headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                          },
                          data: qs.stringify({
                            toMail:
                              email[
                                randomNumber == -1
                                  ? randomNumber + 1
                                  : randomNumber
                              ].mail,
                          }),
                        })
                        .then((response) => {
                          console.log(response.data);
                          Swal.fire(
                            "Gönderildi!",
                            "Başarılı bir şekilde gönderildi",
                            "success"
                          );
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                  });
                }}
              >
                {email.length > 0
                  ? email[randomNumber == -1 ? randomNumber + 1 : randomNumber]
                      .mail
                  : null}
                `in Çekilişini Onayla
              </button>
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
